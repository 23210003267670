import { Box, chakra, Input } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import Heading from "./Heading";
import Para from "./Para";

export default function BlogSidebar(): ReactElement {
  return (
    <Box>
      <Heading level="subsection" fontSize="3xl" mb="0">
        <chakra.span fontSize="xl">Newsletter</chakra.span>
      </Heading>
      <Para fontSize="sm">
        Get new articles and course discounts, straight to your inbox.
      </Para>
      <Input placeholder="me@example.com" />
      <Para fontSize="sm">
        This is a low volume mailing list and we don't pass your details on.
      </Para>
    </Box>
  );
}
