import React, { ReactElement } from "react";
import { graphql, PageProps } from "gatsby";
import SpacePage from "@/components/SpacePage";
import SpaceSection from "@/components/SpaceSection";
import Heading from "@/components/Heading";
import Para from "@/components/Para";
import BlogSidebarWrapper from "@/components/SidebarContainer";
import SidebarContainer from "@/components/SidebarContainer";
import BlogSidebar from "@/components/BlogSidebar";

// This should match `pageQuery` below:
interface MdxEdge {
  post: {
    id: string;
    frontmatter: {
      title?: string;
      updated?: string;
    };
    excerpt: string;
  };
}

// This should match `pageQuery` below:
interface PageData {
  posts: {
    edges: MdxEdge[];
  };
}

export const pageQuery = graphql`
  query PageQuery {
    posts: allMarkdownRemark {
      edges {
        post: node {
          frontmatter {
            title
            updated(fromNow: true)
          }
          excerpt
          id
        }
      }
    }
  }
`;

export default function Blog(props: PageProps<PageData>): ReactElement {
  const {
    data: { posts },
  } = props;

  return (
    <SpacePage title="Blog" accent="purple" activeMenuItem="blog">
      <SpaceSection bg="beige" pb="8">
        <Heading level="page">Blog</Heading>
      </SpaceSection>

      <SpaceSection bg="white" pt="16" pb="32" ct="sm">
        <SidebarContainer sidebar={<BlogSidebar />}>
          {posts.edges.map(({ post }) => (
            <article key={post.id}>
              <Heading level="section">{post.frontmatter.title}</Heading>
              <Para>Updated {post.frontmatter.updated}</Para>
              <Para>{post.excerpt}</Para>
            </article>
          ))}
        </SidebarContainer>
      </SpaceSection>
    </SpacePage>
  );
}
